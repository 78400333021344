import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { PromoCodeList } from 'common/containers/promocode-list';
import { Routes } from 'common/router/constants';

const TITLE = 'Promo Codes';

const ProfilePromoPage: ApplicationPage = () => {
  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{ title: TITLE, defaultBackRoute: Routes.Profile }}
    >
      <PromoCodeList />
    </Page>
  );
};

ProfilePromoPage.getMeta = () => ({ title: TITLE });

ProfilePromoPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfilePromoPage;
